import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fotolavorisalliano',
  templateUrl: './fotolavorisalliano.component.html',
  styleUrls: ['./fotolavorisalliano.component.css']
})
export class FotolavorisallianoComponent implements OnInit {

  images = [
    { img: 'assets/INIZIO_LAVORI_2.JPG', description: '', widt: '200' },
    { img: 'assets/FONDAZIONE_3.JPG', description: '', widt: '200' },
    { img: 'assets/FONDAZIONE_FERRI_4.JPG', description: '', widt: '200' },
    { img: 'assets/FONDAZIONE_GETTO_5.JPG', description: '', widt: '200' },
    { img: 'assets/salliano1.jpg', description: '', widt: '200' },
    { img: 'assets/salliano2.jpg', description: '', widt: '200' },
    { img: 'assets/salliano3.jpg', description: '', widt: '200' },
    { img: 'assets/salliano4.jpg', description: '', widt: '200' }
    
  ];
  constructor() { }

  ngOnInit() {
  }

}
