import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-salliano',
  templateUrl: './salliano.component.html',
  styleUrls: ['./salliano.component.css']
})
export class SallianoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
