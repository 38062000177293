import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.css']
})
export class LinkComponent implements OnInit {

  linkutili = [
    {widt : '200', router : 'https://www.agenziaentrate.gov.it/portale/', description : 'Agenzia delle entrare', photo : 'assets/LOGO_AGENZIAENTRATE.png'},
    {widt : '200', router : 'http://www.georoma.it/dnn80426/', description : 'Collegio Provinciale dei Geometri di Roma', photo : 'assets/LOGO_GEOMETRI_ROMA.png'},
    {widt : '200', router : 'https://www.geoval.it/', description : 'Geometri valutatori esperti', photo : 'assets/LOGO_GEOVAL.png'},
    {widt : '200', router : 'https://www.inarcheck.it/', description : 'Inarcheck', photo : 'assets/LOGO_INARCHECK.png'},
    {widt : '200', router : 'https://www.ording.roma.it/', description : 'Ordine degli Ingegneri della Provincia di Roma', photo : 'assets/LOGO_INGEGNERI_ROMA.png'},
    {widt : '300', router : 'http://www.tribunale.roma.giustizia.it/default.aspx', description : 'Tribunale di Roma', photo : 'assets/LOGO_TRIBUNALE.png'}
  ]
  constructor() { }

  ngOnInit() {
  }

}
