import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bricobravo',
  templateUrl: './bricobravo.component.html',
  styleUrls: ['./bricobravo.component.css']
})
export class BricobravoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
