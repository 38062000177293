import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ChisonoComponent } from './chisono/chisono.component';
import { LinkComponent } from './link/link.component';
import { LavoriComponent } from './lavori/lavori.component';
import { SallianoComponent } from './salliano/salliano.component';
import { BricobravoComponent } from './bricobravo/bricobravo.component';
import { FotolavorisallianoComponent } from './fotolavorisalliano/fotolavorisalliano.component';
import { FotolavoribricobravoComponent } from './fotolavoribricobravo/fotolavoribricobravo.component';
import { FormazioneComponent } from './formazione/formazione.component'


const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'chi-sono', component: ChisonoComponent },
  { path: 'link-utili', component: LinkComponent },
  { path: 'lavori', component: LavoriComponent },
  { path: 'formazione', component: FormazioneComponent },
  { path: 'bricobravo', component: BricobravoComponent },
  { path: 'salliano', component: SallianoComponent },
  { path: 'foto-lavori-salliano', component: FotolavorisallianoComponent },
  { path: 'foto-lavori-bricobravo', component: FotolavoribricobravoComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
