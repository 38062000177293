import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-chisono',
  templateUrl: './chisono.component.html',
  styleUrls: ['./chisono.component.css']
})
export class ChisonoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
