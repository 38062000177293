import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  contacts = [
    { 
      img: 'assets/home_icon.png',
      value: 'Via Acqua Bullicante, 333, Roma, RM'
    },
    { 
      img: 'assets/mail_icon.png',
      value: 'alberto@taddeiengineering.it'
    },
    { 
      img: 'assets/phone.png',
      value: '+39 338 611 5634'
    },
    { 
      img: 'assets/fax.png',
      value: '06 23318567'
    }
  ];
  constructor() { }

  ngOnInit() {
  }

}
