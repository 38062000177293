import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { ChisonoComponent } from './chisono/chisono.component';
import { LinkComponent } from './link/link.component';
import { LavoriComponent } from './lavori/lavori.component';
import { SallianoComponent } from './salliano/salliano.component';
import { BricobravoComponent } from './bricobravo/bricobravo.component';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { FotolavorisallianoComponent } from './fotolavorisalliano/fotolavorisalliano.component';
import { FotolavoribricobravoComponent } from './fotolavoribricobravo/fotolavoribricobravo.component';
import { FormazioneComponent } from './formazione/formazione.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    HomeComponent,
    ChisonoComponent,
    LinkComponent,
    LavoriComponent,
    SallianoComponent,
    BricobravoComponent,
    FotolavorisallianoComponent,
    FotolavoribricobravoComponent,
    FormazioneComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MDBBootstrapModule.forRoot()
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
