import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lavori',
  templateUrl: './lavori.component.html',
  styleUrls: ['./lavori.component.css']
})
export class LavoriComponent implements OnInit {

  collectables = [
    { widt : '80', router: '/salliano', description : 'Lavori Salliano', status : 'concluso', photo : 'assets/ASCENSORE_POST_8.JPG' },
    { widt : '100', router: '/bricobravo', description : 'Lavori BricoBravo', status : 'concluso', photo : "assets/brico.jpg"}
  ];
  constructor() { }

  ngOnInit() {
  }

}
